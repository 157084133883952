.inputHolder {
    width: 45%;
}

@media (max-width: 992px) {
    .inputHolder {
        width: 45%;
    }
}

/* Media query for screens larger than 1200px */
@media (min-width: 1200px) {
    .inputHolder {
        width: 45%;
    }
}
  
  /* Media query for screens smaller than 768px */
  @media (max-width: 768px) {
    .inputHolder {
        width: 85%;
    }
}